<template>
  <DefaultCard
    v-if="internalModel"
    class="d-flex flex-column justify-content-between mr-lg-4 mr-md-4 mt-4"
    ><template #card-content>
      <div class="px-4 py-2">
        <p class="text-grey text-right" style="font-size: 12px">
          {{ createdDate }}, №{{ order.id }}
        </p>
        <b-button
          target="_blank"
          :href="`https://instagram.com/${order.instaname}`"
          style="font-size: 18px !important"
          variant="link"
          size="lg"
          class="mt-2"
        >
          {{ order.instaname }}</b-button
        >
        <div v-if="order.format">
          <h3 style="font-size: 18px !important" class="fw-500 mt-4">
            {{ kindMap[order.format.kind] }}
            {{ order.format.duration ? `${order.format.duration} сек.` : '' }} за
            {{ order.format.uPrice }} ₽
          </h3>
          <p class="mt-3">
            Набор на {{ getCurrentPrMonth }}. {{ getCurrentOrdererType }}.
            {{ order.format.content }}.
          </p>
        </div>
        <h3 style="font-size: 18px !important" class="fw-500 mt-4" v-else>Формат не указан</h3>
        <h3 style="font-size: 18px !important" class="fw-500 mt-5">Тезисы</h3>
        <p class="mt-3 mb-2">{{ order.description }}</p>
        <p class="text-grey text-right" style="font-size: 12px">
          🔥 Заявка сгорит в {{ burnDate }}
        </p>
      </div> </template
    ><template #card-footer>
      <div class="p-4 d-flex flex-row justify-content-between bb-radius">
        <b-button
          v-if="!(order.status === orderStatuses.CANCELED)"
          :class="{ 'ml-auto': !(order.status === orderStatuses.NEW) }"
          @click="() => $emit('refuse')"
          variant="outline-default"
          >Отказать</b-button
        >
        <b-button
          :class="{ 'ml-auto': !(order.status === orderStatuses.NEW) }"
          v-if="!(order.status === orderStatuses.ACCEPTED)"
          @click="accept"
          variant="yellow"
          >{{ order.status === orderStatuses.NEW ? 'Взять на рекламу' : 'Одобрить' }}</b-button
        >
      </div></template
    ></DefaultCard
  >
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import DefaultCard from '@main/components/reusable/cards/DefaultCard.vue';

export default {
  components: {
    DefaultCard,
  },
  model: {
    prop: 'order',
    event: 'input',
  },
  props: {
    order: Object,
  },
  methods: {
    accept() {
      console.log('awd');
      this.internalModel.status = this.orderStatuses.ACCEPTED;
      this.$emit('update-order', this.internalModel);
      this.showRefuseModal = false;
    },
  },
  computed: {
    ...mapState(['bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    getCurrentOrdererType() {
      const { choices } = this.options.pr_types.child.children.target;
      const values = choices.filter((val) => val.value === this.order.format.target);
      if (values.length) {
        const target = values[0].display_name;
        if (target === 'Любой') {
          return 'Личные и коммерческие блоги';
        }
        return target;
      }
      return 'Личные и коммерческие блоги';
    },
    getCurrentPrMonth() {
      const { choices } = this.options.pr_types.child.children.month;
      const values = choices.filter((val) => val.value === this.order.format.month);
      return values[0].display_name;
    },
    burnDate() {
      return moment(this.order.user_confirm_at).add(3, 'd').format('HH:mm DD.MM');
    },
    createdDate() {
      return moment(this.order.user_confirm_at).format('HH:mm DD.MM.YY');
    },
  },
  data: () => ({
    kindMap: {
      1: 'Сторис',
      2: 'Фото-пост',
      3: 'Видео-пост',
      4: 'Сторис + пост',
    },
    orderStatuses: {
      NEW: 'NEW',
      CANCELED: 'CL',
      ACCEPTED: 'AC',
      FINISHED: 'FS',
    },
    internalModel: null,
  }),
  mounted() {
    this.internalModel = this.lodash.cloneDeep(this.order);
  },
};
</script>
