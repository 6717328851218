<template>
  <div class="hovering__pad d-flex flex-column justify-content-between default__card align-top">
    <div><slot name="card-content"></slot></div>
    <div class="bb-radius" style="background-color: #f8f8f8"><slot name="card-footer"></slot></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.default__card {
  max-width: 419px;
  line-height: 24.3px !important;
  display: flex;
}
@media (max-width: 768px) {
  .default__card {
    width: 100% !important;
    max-width: 768px !important;
  }
}
.bb-radius {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
</style>
