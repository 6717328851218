<template>
  <Modal
    v-if="items && items.length"
    title="Отказ"
    v-bind="{ ...$props, ...$attrs }"
    @hide="() => $emit('hide')"
    wrapClass="modal-md"
  >
    <div>
      <div class="d-flex flex-row justify-content-between px-4 py-4 flex-wrap">
        <div
          v-for="chunk in chunkedItems"
          :key="chunk[0].value"
          class="d-flex flex-column justify-content-between mr-md-5"
        >
          <b-radio
            style="border: none !important"
            v-for="item in chunk"
            :key="`${item.value}da@swnn(**hd`"
            :value="item.value"
            v-model="value"
          >
            {{ item.text }}
          </b-radio>
        </div>
      </div>
      <slot name="footer"></slot>
    </div>
  </Modal>
</template>

<script>
import tools from '@main/func/tools';
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    title: String,
    items: Array,
    show: Boolean,
    value: [String, Number, Boolean, Object],
  },
  computed: {
    chunkedItems() {
      if (!this.items && this.items.legth) {
        return [];
      }
      return tools.chunkArray(this.items, 5);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control-label {
  position: relative !important;
  margin-bottom: 0 !important;
  vertical-align: top !important;
  background: transparent !important;
}

::v-deep .custom-radio .custom-control-label::after {
  width: 24px;
  display: none;
  top: 7px;
  top: 0.2px;
  left: 5.1px;
  height: 24px;
}

::v-deep .custom-radio .custom-control-label {
  border: none !important;
  max-width: 300px !important;
  width: 100% !important;
  padding-right: 20px !important;
  height: 24px !important;
  margin-bottom: 3px !important;
  margin-right: 20px !important;
  vertical-align: middle !important;
}
::v-deep .custom-radio .custom-control-label::before {
  top: 2.5px !important;
  left: 0px !important;
}
::v-deep .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  top: 2.5px !important;
  border: 3px double white;
}
::v-deep .custom-radio .custom-control-label {
  padding-left: 24px !important;
}

::v-deep .custom-control {
  padding-left: 0px !important;
}
</style>
