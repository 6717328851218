export default {
  chunkArray(myArray, chunkSize) {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray = [];
    let myChunk;

    for (index = 0; index < arrayLength; index += chunkSize) {
      myChunk = myArray.slice(index, index + chunkSize);
      tempArray.push(myChunk);
    }
    console.log(tempArray);

    return tempArray;
  },
};
