<template>
  <div style="padding: 5px">
    <h2 style="font-size: 24px; font-weight: 400">Заявки на рекламу из @easyprbot</h2>
    <!-- МЕНЮШКА СО ВКЛАДКАМИ И ПОИСКОМ -->
    <div class="d-flex flex-row justify-content-between flex-wrap">
      <b-form-radio-group
        class="mt-4"
        v-model="filterParams.status"
        :options="statusMap"
        button-variant="outline-default"
        size="md"
        name="radio-btn-outline"
        buttons
      ></b-form-radio-group>
      <b-input-group class="mt-4" style="min-width: 230px !important; width: 230px !important">
        <b-form-input
          v-model="filterParams.search"
          type="text"
          placeholder="Поиск (дата или ник)"
        ></b-form-input>
        <b-input-group-append>
          <b-button @click="updateData" variant="outline-default"
            ><b-icon icon="search"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div
      v-if="!currentUser || !currentUser.blogger_profile || !currentUser.blogger_profile.filled"
      class="hovering__pad d-flex flex-wrap justify-content-between flex-row p-4 mt-4 align-items-center"
    >
      <h2 class="gathering__h2 mb-4 mb-llg-0">
        ❗Загрузите вашу карточку на сайт, чтобы принимать заявки на рекламу
      </h2>
      <b-button :to="{ name: 'fill_statistics' }" size="lg" variant="yellow"
        >Загрузить карточку блогера</b-button
      >
    </div>
    <div class="mt-5" v-else>
      <div
        v-if="!currentUser.services_subscriber"
        class="hovering__pad p-3 py-4 d-md-flex align-items-center justify-content-between"
        style="background: #fffce3; line-height: 16.59px"
      >
        <p class="d-md-inline-block mr-md-4">
          Без подписки вы можете видеть только заявки от заказчиков, которые оплатили подписку на
          сайте.<br /><br />
          Чтобы смотреть и отвечать на все заявки на сайте, купите подписку.
        </p>

        <b-button
          :to="{ name: 'payments-settings' }"
          class="advapadbtn w-100 py-2 mt-3 mt-md-0"
          variant="yellow"
          >Купить подписку за 1 990 ₽ в месяц</b-button
        >
      </div>
      <div
        id="moauwo*(hlnda"
        class="hovering__pad p-3 py-4 mt-4 d-md-flex align-items-center justify-content-between"
        style="background: #ecf5fe; line-height: 16.59px"
      >
        <p class="d-md-inline-block mr-md-4">
          Если блогер часто игнорирует заявки, то у него в карточке будет написано, что он не
          отвечает по заявкам на сайте и его приоритетность будет снижена
        </p>

        <b-button
          class="advapadbtn w-100 py-2 mt-3 mt-md-0"
          v-hideon="'moauwo*(hlnda'"
          variant="outline-default"
          >Больше не показывать</b-button
        >
      </div>
    </div>
    <!-- КАРТОЧКИ ЗАЯВОК -->
    <template v-if="orders && orders.results.length">
      <div class="mt-5 mb-5 d-flex flex-wrap">
        <template>
          <template v-for="order in orders.results">
            <ApplicationCard
              @update-order="updateOrder"
              @refuse="selectOrder(order)"
              :key="`${order.id}awdaowidan(*OrdrJd1`"
              :order="order"
            />
          </template>
        </template>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <Pagination
          v-if="orders.total_pages > 1"
          :current="filterParams.page"
          :total="orders.total_pages"
          @page-change="pageChangeHandler"
        />
      </div>
    </template>
    <RadioModal
      v-if="selectedOrder"
      :title="'Отказ'"
      :items="[
        ...refuseReasons.map((val) => ({ text: val.bDescription, value: val.command })),
        { text: 'В бан навсегда', value: 'ban' },
      ]"
      v-model="selectedOrder.cancel_reason"
      :show="!!selectedOrder"
      @hide="selectedOrder = null"
      ><template #footer>
        <div class="px-4 pb-4">
          <b-button @click="selectedOrder = null" variant="outline-default">Отмена</b-button
          ><b-button
            class="ml-3"
            @click="
              () => {
                updateOrder({ ...selectedOrder, status: 'CL' });
                selectedOrder = null;
              }
            "
            variant="yellow"
            >Применить</b-button
          >
        </div></template
      ></RadioModal
    >
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import RadioModal from '@main/components/reusable/modals/RadioModal.vue';
import Pagination from '@main/components/Pagination.vue';
import promobot from '@main/api/promobot';
import ApplicationCard from './ApplicationCard.vue';

export default {
  components: {
    ApplicationCard,
    Pagination,
    RadioModal,
  },
  data: () => ({
    orders: null,
    filterParams: {
      page: 1,
      page_size: 9,
      status: 'NEW',
      search: '',
    },
    statusMap: [
      { text: 'Свежие', value: 'NEW' },
      { text: 'Одобренные', value: 'AC' },
      { text: 'Отклоненные', value: 'CL' },
    ],
    updateInterval: null,
    selectedOrder: null,
  }),
  methods: {
    ...mapMutations('payments', ['toggleSubscriptionPriceModal']),
    ...mapMutations('notifications', ['showMessage']),
    async updateData() {
      this.orders = await promobot.orders.list(this.filterParams);
    },
    selectOrder(order) {
      this.selectedOrder = this.lodash.cloneDeep(order);
    },
    async updateOrder(data) {
      await promobot.orders.partial_update(data);
      await this.updateData();
    },
    setFilterParams(val) {
      this.$set(this, 'filterParams', { ...this.filterParams, ...val });
    },
    async pageChangeHandler(page) {
      this.setFilterParams({ page });
      await this.updateData();
    },
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapState('promobot', ['refuseReasons']),
  },
  watch: {
    'filterParams.status': {
      async handler() {
        await this.updateData();
      },
    },
    selectedOrder(val) {
      console.log(val);
    },
  },
  async mounted() {
    await this.updateData();
    this.updateInterval = setInterval(async () => {
      await this.updateData();
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .advapadbtn {
    width: auto !important;
  }
}
</style>
